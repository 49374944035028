<template>
    <div class="md-layout">
        <div class="md-layout-item md-size-35">
            <md-card>
                <md-card-header class="md-card-header-icon md-card-header-blue">
                <div class="card-icon">
                    <md-icon>image</md-icon>
                </div>
                <h4 class="title">Thumbnail</h4>
                </md-card-header>
                <md-card-content>
                    <img class="edit-image" :src="content.thumbnailUrl" />
                    <div class="md-layout md-alignment-center-center">
                        <div class="md-layout-item md-size-70">
                            <md-field>
                                <label>이미지 선택</label>
                                <md-file @change="onChangeFile($event, 'thumbnail')" accept="image/*" />
                            </md-field>
                        </div>
                        <div class="md-layout-item md-size-30">
                            <md-button @click="saveThumbnail" class="md-dense md-raised md-primary">저장</md-button>
                        </div>
                    </div>
                </md-card-content>
            </md-card>
        </div>
        <div class="md-layout-item md-size-65">
            <md-card>
                <md-card-header class="md-card-header-icon md-card-header-green">
                <div class="card-icon">
                    <md-icon>edit</md-icon>
                </div>
                <h4 class="title">Content</h4>
                </md-card-header>
                <md-card-content>
                    <div class="md-layout md-alignment-center-center">
                        <div class="md-layout-item">
                            <md-field class="edit-form">
                                <label for="movie">Type</label>
                                <md-select v-model="content.dataType" name="dataType" id="dataType">
                                    <md-option value="image">image</md-option>
                                    <md-option value="youtube">youtube</md-option>
                                    <md-option value="pdf">pdf</md-option>
                                    <md-option value="video">video(mp4)</md-option>
                                </md-select>
                            </md-field>

                            <md-field v-if="content.dataType === 'youtube'" class="edit-form">
                                <label>Youtube embed url</label>
                                <md-input v-model="content.dataUrl"></md-input>
                            </md-field>
                            <md-field v-else class="edit-form">
                                <label>파일 선택(50MB 이하)</label>
                                <md-file v-model="content.dataUrl" @change="onChangeFile($event, 'data')" accept="*" />
                            </md-field>
                            
                            <div class="md-layout mt">
                                <div class="md-layout-item md-size-50">
                                    <md-field>
                                        <label>Title(KO)</label>
                                        <md-input v-model="content.dataTitle"></md-input>
                                    </md-field>
                                    
                                    <md-field>
                                        <label>Description(KO)</label>
                                        <md-textarea v-model="content.dataDescription"></md-textarea>
                                        <md-icon>description</md-icon>
                                    </md-field>
                                </div>
                                <div class="md-layout-item md-size-50">
                                    <md-field>
                                        <label>Title(EN)</label>
                                        <md-input v-model="subLang.eng.title"></md-input>
                                    </md-field>
                                    
                                    <md-field>
                                        <label>Description(EN)</label>
                                        <md-textarea v-model="subLang.eng.description"></md-textarea>
                                        <md-icon>description</md-icon>
                                    </md-field>
                                </div>
                                <div class="md-layout-item md-size-50">
                                    <md-field>
                                        <label>Title(CN)</label>
                                        <md-input v-model="subLang.cn.title"></md-input>
                                    </md-field>
                                    
                                    <md-field>
                                        <label>Description(CN)</label>
                                        <md-textarea v-model="subLang.cn.description"></md-textarea>
                                        <md-icon>description</md-icon>
                                    </md-field>
                                </div>
                                <div class="md-layout-item md-size-50">
                                    <md-field>
                                        <label>Title(JP)</label>
                                        <md-input v-model="subLang.jp.title"></md-input>
                                    </md-field>
                                    
                                    <md-field>
                                        <label>Description(JP)</label>
                                        <md-textarea v-model="subLang.jp.description"></md-textarea>
                                        <md-icon>description</md-icon>
                                    </md-field>
                                </div>
                            </div>
                            <div v-if="content.metaData" class="md-layout mt">
                                <div class="md-layout-item md-medium-size-50">
                                    <md-field>
                                        <label>Link name</label>
                                        <md-input v-model="content.metaData.linkName"></md-input>
                                    </md-field>
                                </div>
                                <div v-if="content.subLang" class="md-layout-item md-medium-size-50">
                                    <md-field>
                                        <label>Link</label>
                                        <md-input v-model="content.metaData.link"></md-input>
                                    </md-field>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="edit-form md-layout md-alignment-center-right">
                        <div class="md-layout-item md-size-15">
                            <md-button @click="updateContent" class="md-dense md-raised md-primary">저장</md-button>
                        </div>
                    </div>
                </md-card-content>
            </md-card>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import api from "@/commons/apiUrl";
import imageCompression from 'browser-image-compression';

export default {
    data() {
        return {
            content: {},
            sceneId: null,
            number: null,
            thumbnailFile: null,
            dataFile: null,
            langType: "ko",
            subLang: {
                eng: {
                    title: "",
                    description: ""
                },
                cn: {
                    title: "",
                    description: ""
                },
                jp: {
                    title: "",
                    description: ""
                }
            }
        }
    },
    async created() {
        this.sceneId = this.$route.params.sceneId;
        this.number = this.$route.params.number;

        const res = await axios.get(api.GET_DATA_DETAIL(this.sceneId, this.number)
            ,{ 
                headers: { 
                    authorization: this.$store.getters.getToken,
                }
            });
        if(!res.data.success) {
            return;
        }

        // thumbnail, data url
        if(res.data.data.thumbnailUrl) {
            res.data.data.thumbnailUrl = process.env.VUE_APP_API_BASE_URL + "/" + res.data.data.thumbnailUrl;
        }
        if(res.data.data.dataUrl && res.data.data.dataType != "youtube") {
            res.data.data.dataUrl = process.env.VUE_APP_API_BASE_URL + "/" + res.data.data.dataUrl;
        }

        // subLang process
        if(res.data.data.subLang) {
            const checkList = ["eng", "cn", "jp"];
            checkList.forEach((prop) => {
                if(Object.hasOwnProperty.bind(res.data.data.subLang)(prop)) {
                    this.subLang[prop] = res.data.data.subLang[prop];
                }
            });
        }

        this.content = res.data.data;
        
        if(!this.content.metaData) {
            this.content.metaData = {
                "linkName": "",
                "link": ""
            }
        }
    },
    methods: {
        async saveThumbnail() {
            if(!this.thumbnailFile) {
                this.$store.dispatch("alerts/error", "썸네일 선택 후 저장해 주세요.");

                return ;
            }
            const data = new FormData();
            data.append("data", this.thumbnailFile);

            const res = await axios.put(api.UPDATE_DATA_THUMBNAIL(this.sceneId, this.number),
                data,
                { 
                    headers: { 
                        authorization: this.$store.getters.getToken,
                    }
                });

            if(!res.data.success) {
                this.$store.dispatch("alerts/error", "썸네일 저장 실패");

                return;
            }

            this.$store.dispatch("alerts/success", "썸네일 저장 성공");
        },
        async onChangeFile(e, type) {
            let file = e.target.files[0];
            if(file.type === "image/jpeg" || file.type === "image/png") {
                const options = {
                    maxSizeMB: 1,
                    maxWidthOrHeight: 1920,
                    useWebWorker: true,
                }
                file = await imageCompression(file, options);
            }

            if(type === "thumbnail") {
                this.thumbnailFile = file;
                this.content.thumbnailUrl = URL.createObjectURL(this.thumbnailFile);
            }
            else {
                this.dataFile = file;
            }
        },
        async updateContent() {
            const regex = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
            if(this.content.metaData.link && this.content.metaData.link.length > 0 && !regex.test(this.content.metaData.link)) {
                this.$store.dispatch("alerts/error", "유효하지 않은 주소입니다.\n http://www.kf.com과 같이 입력해 주세요.");
                
                return;
            }

            const data = new FormData();
            
            this.dataFile ? data.append("data", this.dataFile) : null;
            this.content.dataType === "youtube" ? data.append("dataUrl", this.content.dataUrl) : null;
            // this.content.dataUrl ? data.append("dataUrl", this.content.dataUrl) : null;
            this.content.metaData.linkName ? data.append("linkName", this.content.metaData.linkName) : null;
            this.content.metaData.link ? data.append("link", this.content.metaData.link) : null;

            data.append("engTitle", this.subLang.eng.title);
            data.append("engDescription", this.subLang.eng.description);
            data.append("cnTitle", this.subLang.cn.title);
            data.append("cnDescription", this.subLang.cn.description);
            data.append("jpTitle", this.subLang.jp.title);
            data.append("jpDescription", this.subLang.jp.description);

            data.append("dataType", this.content.dataType);
            data.append("dataTitle", this.content.dataTitle);
            data.append("dataDescription", this.content.dataDescription);
            
            const res = await axios.put(api.UPDATE_DATA(this.sceneId, this.number),
                data,
                { 
                    headers: { 
                        authorization: this.$store.getters.getToken,
                    }
                }
            );

            if(!res.data.success) {
                this.$store.dispatch("alerts/error", "저장 실패");

                return;
            }

            this.$store.dispatch("alerts/success", "저장 성공");
        }
    }
}
</script>

<style scoped>
.edit-image {
    width: 100%;
}
.textarea-tab {
    color: black;
}
.edit-form {
    margin-top: 2rem;
}
.md-field+.md-has-textarea:not(.md-autogrow) {
    margin-top: 0px !important;
}
.mt {
    margin-top: 2rem;
}
</style>